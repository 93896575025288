import { FilterFunction } from '@filters/types';
import { transformToDateFilterInput } from '@helpers/filters';
import { CustomerObject } from '@ui/form/ContactsANDCompaniesSelect';
import dayjs from 'dayjs';

export const createMemoReportProductFilter: FilterFunction<string> = (
  productId?: string,
): ProductInventoryItemEventFiltersInput => {
  return productId
    ? {
        productInventoryItem: {
          product: {
            id: {
              eq: productId,
            },
          },
        },
      }
    : {};
};

export const createMemoReportLocationFilter: FilterFunction<string> = (
  locationId?: string,
): ProductInventoryItemEventFiltersInput => {
  return locationId
    ? {
        businessLocation: {
          id: {
            eq: locationId,
          },
        },
      }
    : {};
};

export const createMemoReportVendorFilter: FilterFunction<string> = (
  vendorId?: string,
): ProductInventoryItemEventFiltersInput => {
  const vendorData = vendorId as CustomerObject;

  return vendorData.contact
    ? {
        itemContactVendor: {
          id: {
            eq: vendorData.contact,
          },
        },
      }
    : {
        itemVendor: {
          id: {
            eq: vendorData.company || undefined,
          },
        },
      };
};

export const createMemoReportReceiveDateFilter = (
  receiveDate?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null,
): ProductInventoryItemEventFiltersInput => {
  return receiveDate
    ? {
        ...(receiveDate && {
          receiveDate: transformToDateFilterInput(receiveDate),
        }),
      }
    : {};
};

export const createMemoReportExpiryDateFilter = (
  expiryDate?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null,
): ProductInventoryItemEventFiltersInput => {
  return expiryDate
    ? {
        ...(expiryDate && {
          expiryDate: transformToDateFilterInput(expiryDate),
        }),
      }
    : {};
};
