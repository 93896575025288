export enum ActivityType {
  TASK = 'task',
  MEETING = 'meeting',
  MAIL = 'mail',
  VOICE_MAIL = 'voice_mail',
  POINTS = 'points',
  CALL = 'call',
}

export enum LeadSource {
  ADVERTISEMENT = 'advertisement',
  EXTERNAL_REFERRAL = 'external_referral',
  ONLINE_STORE = 'online_store',
  WEBSITE = 'website',
  UNKNOWN = 'unknown',
}

export enum CustomerPriceGroup {
  RETAIL = 'retail',
  WHOLESALE = 'wholesale',
}

export enum CompanyType {
  PROSPECT = 'prospect',
  VENDOR = 'vendor',
}

export enum LeadStage {
  DEFAUlT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum PriorityType {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  INACTIVE = 'inactive',
}

export enum DeliveryMethod {
  COURIER = 'Courier',
  FEDEX = 'FedEx',
  DHL_EXPRESS = 'DHL_Express',
  ONTRACK = 'OnTrac',
  UPS = 'UPS',
  USPS = 'USPS',
  US_FOODS = 'US_Foods',
}

export enum OrderType {
  SELL = 'sell',
  LAYAWAY = 'layaway',
  RENT = 'rent',
  TRADEIN = 'tradeIn',
  PURCHASE = 'purchase',
}

export enum SalesItemReportType {
  PRODUCT = 'product',
  COMPOSITE_PRODUCT = 'composite_product',
  SERVICE = 'service',
  MEMBERSHIP = 'membership',
  CLASS = 'class',
}

export enum CustomerType {
  CONTACT = 'contact',
  COMPANY = 'company',
  LEAD = 'lead',
}

export enum EnumSizeUnit {
  CM = 'cm',
  FT = 'ft',
  IN = 'in',
  M = 'm',
  MM = 'mm',
  YD = 'yd',
}

export enum EnumWeightUnit {
  MG = 'mg',
  G = 'g',
  KG = 'kg',
  GR = 'gr',
  OZ = 'oz',
  LB = 'lb',
  CT = 'ct',
}

export enum StatisticCardFormsStages {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  DECLINED = 'declined',
}

export enum StatisticCardTransactionsStages {
  PAID = 'accepted',
  OUTSTANDING = 'pending',
  OVERDUE = 'overdue',
}

export enum Purchase {
  PAID = 'accepted',
  OUTSTANDING = 'pending',
  OVERDUE = 'overdue',
}

export enum StatisticCardAppraisalStages {
  COMPLETED = 'completed',
}

export type PrintSizeType = 'LARGE' | 'MEDIUM' | 'SMALL';
