import { StatusMessageContextType } from '@app/StatusMessageContext/statusMessageContext';

export const handleApplicationError = (
  error: unknown,
  message: StatusMessageContextType,
  errorMessage?: string,
) => {
  if (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as { message: string }).message === 'string'
  ) {
    const errMsg = (error as { message: string }).message;
    if (errMsg.startsWith('ApplicationError: Custom: ')) {
      return message.open(
        'error',
        errMsg.replace('ApplicationError: Custom: ', ''),
      );
    }
    return message.open('error', errorMessage);
  }
  message.open('error', errorMessage);
};
