import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';
import { BusinessLocationsSelect } from '@ui/form/BusinessLocationsSelect';
import EmployeeSelect from '@ui/form/EmployeeSelect';
import { SublocationSelect } from '@ui/form/SublocationSelect';
import { DatePicker } from 'antd';
import { FC, useState } from 'react';

export const InventoryAuditAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => {
  const [selectedBusinessLocation, setSelectedBusinessLocation] =
    useState<string>('');

  const handleSetSelectedBusinessLocation = (value: string) => {
    setSelectedBusinessLocation(value);
  };
  return (
    <>
      <ExpandableFormItem
        name={'businessLocation'}
        label={'Business Location'}
        defaultOpen={!!values?.businessLocation}
      >
        <BusinessLocationsSelect
          placeholder={'Choose location'}
          storesOnly={false}
          onChange={handleSetSelectedBusinessLocation}
        />
      </ExpandableFormItem>

      <ExpandableFormItem
        label={'Sublocation'}
        name={'sublocation'}
        defaultOpen={!!values?.sublocation}
        withDivider
      >
        <SublocationSelect
          placeholder={'Choose sublocation'}
          selectedStore={selectedBusinessLocation}
        />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Employee'}
        name={'employee'}
        defaultOpen={!!values?.employee}
        withDivider
      >
        <EmployeeSelect />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Audit Date'}
        name={'auditDate'}
        defaultOpen={!!values?.auditDate}
        withDivider
      >
        <DatePicker style={{ width: '100%', padding: '11px 15px' }} />
      </ExpandableFormItem>

      <ExpandableFormItem
        label={'Date Created'}
        name={'createdAt'}
        defaultOpen={!!values?.createdAt}
        withDivider
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
    </>
  );
};
