import { FC, useEffect, useMemo, useState } from 'react';

import { Badge, Empty, Flex, MenuProps } from 'antd';

import { Icon } from '@assets/icon';

import { useReadNotificationMutation } from '@/graphql';
import { useSocket } from '@/socket/useSocket';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import NotificationItem from '@components/notifications/NotificationItem/NotificationItem';
import { useNotificationsData } from '@components/notifications/useNotificationsData';
import { useUnreadNotificationsCount } from '@components/notifications/useUnreadNotificationsCount';
import { isNotEmpty } from '@helpers/types';
import { useToken } from '@hooks/useToken';
import { CustomButton } from '@ui/button/Button';
import CustomDropdown from '@ui/dropdown';
import { ItemType } from 'antd/es/menu/interface';

const EMPTY_NOTIFICATIONS_ITEM: ItemType = {
  key: 'empty',
  label: <Empty description={'No notifications'} />,
};
export const NotificationsDropdown: FC = () => {
  const socket = useSocket();
  const message = useStatusMessage();
  const token = useToken();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { unreadCount, refetch: refetchUnreadCount } =
    useUnreadNotificationsCount();

  const {
    data,
    loading,
    refetchData,
    fetchMore,
    fetchData,
    hasMoreItems,
    resetPageSize,
  } = useNotificationsData();

  useEffect(() => {
    socket.on('notification-created', () => {
      refetchUnreadCount();

      if (isDropdownOpen) {
        refetchData();
      }

      message.open('info', 'You have 1 new notification!');
    });

    return () => {
      socket.off('notification-created');
    };
  }, [socket, message, refetchData, refetchUnreadCount, isDropdownOpen]);

  useEffect(() => {
    if (isDropdownOpen) {
      fetchData();
    }

    if (!isDropdownOpen) {
      resetPageSize();
    }
  }, [isDropdownOpen, fetchData, resetPageSize]);

  const [readNotification] = useReadNotificationMutation({
    onCompleted: () => {
      refetchUnreadCount();
      refetchData();
    },
  });

  const items: MenuProps['items'] = useMemo(() => {
    const handleReadNotification = (id: string) => {
      readNotification({ variables: { id } });
    };

    return (
      data?.userNotifications?.data
        ?.map((item) => {
          const divider = {
            key: `divider ${item?.id}`,
            type: 'divider',
          };

          const notificationItem = {
            key: item?.id,
            label: (
              <NotificationItem
                notification={item}
                handleReadNotification={handleReadNotification}
              />
            ),
          };

          return [divider, notificationItem] as MenuProps['items'];
        })
        .filter(isNotEmpty)
        .flatMap((x) => x) || []
    );
  }, [data, readNotification]);

  const loadMoreItem: ItemType = {
    key: 'load-more',
    label: (
      <Flex justify={'center'}>
        <CustomButton
          size={'small'}
          disabled={!hasMoreItems || loading}
          onClick={(e) => {
            e.stopPropagation();
            fetchMore();
          }}
          loading={loading}
        >
          Load more
        </CustomButton>
      </Flex>
    ),
  };

  return (
    <CustomDropdown
      menu={{
        items: items?.length
          ? [...items, ...(hasMoreItems ? [loadMoreItem] : [])]
          : [EMPTY_NOTIFICATIONS_ITEM],
      }}
      titleProps={{
        title: 'Notifications',
      }}
      onOpenChange={(open) => setIsDropdownOpen(open)}
      destroyPopupOnHide={true}
    >
      <Badge
        count={unreadCount}
        size={'default'}
        color={token.token.colorPrimary}
      >
        <Icon type={'bell'} size={24} styles={{ display: 'flex' }} />
      </Badge>
    </CustomDropdown>
  );
};
