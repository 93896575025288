import { useMeQuery } from '@/graphql';
import { useSocket } from '@/socket/useSocket';
import { Device } from '@twilio/voice-sdk';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import CallComponent from './CallComponent';

interface CallContextProps {
  isCallActive: boolean;
  isIncomingCall: boolean;
  duration: string;
  isMuted: boolean;
  toggleMute: () => void;
  handleDecline: () => void;
  handleAccept: () => void;
  makeOutgoingCall: (phoneNumber: string, contactName: string) => void;
}

const CallContext = createContext<CallContextProps | undefined>(undefined);

export const CallProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isCallActive, setIsCallActive] = useState(false);
  const [isIncomingCall, setIsIncomingCall] = useState(false);
  const [callSid, setCallSid] = useState<string | null>(null);
  const [isMuted, setIsMuted] = useState(false);
  const [duration, setDuration] = useState('00:00');
  const [device, setDevice] = useState<Device | null>(null);
  const [call, setCall] = useState<any>(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactName, setContactName] = useState('');
  const { data: me, loading } = useMeQuery();

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const socket = useSocket();

  const startTimer = () => {
    let totalSeconds = 0;
    timerRef.current = setInterval(() => {
      totalSeconds += 1;
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      setDuration(
        `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
          2,
          '0',
        )}`,
      );
    }, 1000);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  const cleanupCallData = useCallback(() => {
    setIsCallActive(false);
    setIsIncomingCall(false);
    stopTimer();
    setDuration('00:00');
    setPhoneNumber('');
    setContactName('');
  }, []);

  useEffect(() => {
    if (loading || !me) return;

    const initTwilioDevice = async () => {
      try {
        const email = me?.me?.attributes?.email;
        const twilioConnectionId =
          me?.me?.attributes?.tenant?.data?.attributes?.twilioConnection?.data
            ?.id;
        if (!email) return;
        if (!twilioConnectionId) return;

        const tokenResponse = await fetch(
          `/api/twilio/token?email=${encodeURIComponent(email)}`,
        );
        const { token } = await tokenResponse.json();

        const twilioDevice = new Device(token, { logLevel: 2 });

        twilioDevice.on('registered', () => {
          console.log('Twilio Device is ready to make calls.');
        });

        twilioDevice.on('incoming', (call) => {
          setCall(call);
          setPhoneNumber(call.parameters.From);
          setIsIncomingCall(true);
        });

        twilioDevice.on('error', (error) => {
          console.error('Twilio Device Error:', error.message);
        });

        twilioDevice.register();
        setDevice(twilioDevice);
      } catch (err) {
        console.error('Error initializing Twilio Device:', err);
      }
    };

    initTwilioDevice();

    const getMicrophoneAccess = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
      } catch (err) {
        console.error('Microphone permission denied:', err);
      }
    };

    getMicrophoneAccess();

    return () => {
      stopTimer();
    };
  }, [me, loading]);

  const makeOutgoingCall = async (phone: string, name: string) => {
    if (!device) {
      console.error('Twilio Device not initialized.');
      return;
    }

    setPhoneNumber(phone);
    setContactName(name);

    try {
      const params = { To: phone, From: me?.me?.attributes?.fullName ?? '' };
      const call = await device.connect({ params });
      setCall(call);

      call.on('accept', () => {
        setIsCallActive(true);
        startTimer();
      });

      call.on('disconnect', () => {
        cleanupCallData();
      });

      call.on('closed', () => {
        cleanupCallData();
      });

      call.on('error', (error) => {
        console.error('Call error:', error.message);
      });
    } catch (err) {
      console.error('Error initiating outgoing call:', err);
    }
  };

  const handleDecline = () => {
    if (call) {
      if (isCallActive) {
        call.disconnect();
        cleanupCallData();
      } else {
        call.reject();
        cleanupCallData();
      }
    }
  };

  const handleAccept = () => {
    if (call) {
      call.accept();
      setIsIncomingCall(false);
      setIsCallActive(true);
      startTimer();
    }
  };

  const toggleMute = () => {
    if (call) {
      if (isMuted) {
        call.mute(false);
      } else {
        call.mute(true);
      }
      setIsMuted((prevMuted) => !prevMuted);
    }
  };

  useEffect(() => {
    socket.on('callStatus', (data) => {
      if (data.callSid) {
        setCallSid(data.callSid);
      }
      if (data.status === 'initiated') {
        setIsCallActive(true);
        startTimer();
      } else if (
        data.status === 'completed' ||
        data.status === 'disconnected' ||
        data.status === 'canceled' ||
        data.status === 'busy' ||
        data.status === 'no-answer'
      ) {
        cleanupCallData();
      } else if (data.status === 'error') {
        console.error('Error:', data.message);
      }
    });

    return () => {
      socket.off('callStatus');
    };
  }, [cleanupCallData, socket]);

  return (
    <CallContext.Provider
      value={{
        isCallActive,
        isIncomingCall,
        duration,
        isMuted,
        toggleMute,
        handleDecline,
        handleAccept,
        makeOutgoingCall,
      }}
    >
      {children}
      <CallComponent
        phoneNumber={phoneNumber}
        contactName={contactName}
        isCallActive={isCallActive}
        isIncomingCall={isIncomingCall}
        duration={duration}
        isMuted={isMuted}
        toggleMute={toggleMute}
        handleDecline={handleDecline}
        handleAccept={handleAccept}
      />
    </CallContext.Provider>
  );
};

export const useCall = () => {
  const context = useContext(CallContext);
  if (context === undefined) {
    throw new Error('useCall must be used within a CallProvider');
  }
  return context;
};
