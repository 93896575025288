import { cdnUrl } from '@helpers/external';

export const getCdnImage = (imageUrl: string | undefined): string => {
  if (!imageUrl) return '';

  const isImageUrlFromCdn = imageUrl.includes('cdn.');

  // TODO: Valentyn V. or Pavlo - change 'vertical-saas' to 'uploads' after file upload path will be changed
  return cdnUrl && !isImageUrlFromCdn
    ? imageUrl.replace(/^.*?(\/vertical-saas\/.*)$/, `${cdnUrl}$1`)
    : imageUrl;
};
