import { FC, useState } from 'react';

import { BusinessLocationsSelect } from '@ui/form/BusinessLocationsSelect';
import { ProductSelect } from '@ui/form/ProductSelect/ProductSelect';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import EmployeeSelect from '@ui/form/EmployeeSelect';
import { SublocationSelect } from '@ui/form/SublocationSelect';
import { DatePicker } from 'antd';

export const TransferOrderAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => {
  const [selectedBusinessLocationFrom, setSelectedBusinessLocationFrom] =
    useState<string>('');
  const [selectedBusinessLocationTo, setSelectedBusinessLocationTo] =
    useState<string>('');

  const handleSetSelectedBusinessLocationFrom = (value: string) => {
    setSelectedBusinessLocationFrom(value);
  };

  const handleSetSelectedBusinessLocationTo = (value: string) => {
    setSelectedBusinessLocationTo(value);
  };

  return (
    <>
      <ExpandableFormItem
        name={'locationFrom'}
        label={'Source Location'}
        defaultOpen={!!values?.locationFrom}
      >
        <BusinessLocationsSelect
          placeholder={'Choose source location'}
          storesOnly={false}
          onChange={handleSetSelectedBusinessLocationFrom}
        />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Source Sublocation'}
        name={'sublocationFrom'}
        defaultOpen={!!values?.sublocationFrom}
        withDivider
      >
        <SublocationSelect
          placeholder={'Choose source sublocation'}
          selectedStore={selectedBusinessLocationFrom}
        />
      </ExpandableFormItem>
      <ExpandableFormItem
        name={'locationTo'}
        label={'Destination Location'}
        defaultOpen={!!values?.locationTo}
        withDivider
      >
        <BusinessLocationsSelect
          placeholder={'Choose destination location'}
          storesOnly={false}
          onChange={handleSetSelectedBusinessLocationTo}
        />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Destination Sublocation'}
        name={'sublocationTo'}
        defaultOpen={!!values?.sublocationTo}
        withDivider
      >
        <SublocationSelect
          placeholder={'Choose destination sublocation'}
          selectedStore={selectedBusinessLocationTo}
        />
      </ExpandableFormItem>
      <ExpandableFormItem
        name={'product'}
        label={'Product'}
        defaultOpen={!!values?.product}
        withDivider
      >
        <ProductSelect placeholder={'Choose product'} />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Employee'}
        name={'employee'}
        defaultOpen={!!values?.employee}
        withDivider
      >
        <EmployeeSelect />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Transfer Date'}
        name={'orderDate'}
        defaultOpen={!!values?.orderDate}
        withDivider
      >
        <DatePicker style={{ width: '100%', padding: '11px 15px' }} />
      </ExpandableFormItem>

      <ExpandableFormItem
        label={'Date Created'}
        name={'createdAt'}
        defaultOpen={!!values?.createdAt}
        withDivider
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
    </>
  );
};
