import React, { FC, ReactNode } from 'react';

import { ButtonProps, Col, Flex, Row, Space, Switch, Typography } from 'antd';

import { Icon, IconSize } from '@assets/icon';
import { CustomButton } from '@ui/button/Button';
import { CustomDivider } from '@ui/divider/Divider';
import { BaseButtonProps } from 'antd/es/button/button';

interface Props {
  title: string | ReactNode;
  description?: string;
  primaryAction?: string;
  secondaryAction?: string;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
  buttons?: React.ReactNode[];
  titleLevel?: 1 | 2 | 3 | 4 | 5 | undefined;
  primaryButtonProps?: BaseButtonProps;
  secondaryButtonProps?: ButtonProps;
  isEditable?: boolean;
  onEdit?: () => void;
  switcherOptions?: {
    onCheckedText: string;
    onUncheckedText: string;
    state: boolean;
    setState: (state: boolean) => void;
  };
  descriptionButtonProps?: ButtonProps;
  descriptionGap?: number;
}

const ModuleTitle: FC<Props> = ({
  title,
  primaryAction,
  secondaryAction,
  onPrimaryClick,
  onSecondaryClick,
  buttons,
  description,
  titleLevel = 1,
  primaryButtonProps,
  secondaryButtonProps,
  isEditable,
  onEdit,
  switcherOptions,
  descriptionButtonProps,
  descriptionGap = 15,
}) => {
  const handleSwitchClick = (checked: boolean) => {
    if (switcherOptions) {
      switcherOptions.setState(checked);
    }
  };

  return (
    <>
      <Row justify={'space-between'}>
        <Col>
          <Flex gap={10} align={'center'}>
            <Typography.Title
              style={{ textTransform: 'capitalize' }}
              level={titleLevel}
            >
              {title}
            </Typography.Title>
            {isEditable && (
              <CustomButton
                type={'text'}
                icon={<Icon type={'edit'} size={IconSize.Large} />}
                onClick={onEdit}
                size={'large'}
              />
            )}
          </Flex>
        </Col>
        <Col>
          <Row align={'stretch'} gutter={16}>
            <Col>
              {buttons && (
                <Space size={5} align={'center'} style={{ height: '100%' }}>
                  {buttons.map((button, index) => (
                    <React.Fragment key={index}>{button}</React.Fragment>
                  ))}
                </Space>
              )}
            </Col>
            {(secondaryAction || primaryAction) && (
              <>
                {buttons && (
                  <Col>
                    <CustomDivider
                      type={'vertical'}
                      style={{ height: '100%' }}
                    />
                  </Col>
                )}
                <Col>
                  <Space size={16} align={'center'} style={{ height: '100%' }}>
                    {secondaryAction && (
                      <CustomButton
                        size={'large'}
                        onClick={onSecondaryClick}
                        style={{ minWidth: '140px' }}
                        {...secondaryButtonProps}
                      >
                        {secondaryAction}
                      </CustomButton>
                    )}
                    {primaryAction && (
                      <CustomButton
                        size={'large'}
                        type={'primary'}
                        onClick={onPrimaryClick}
                        style={{ minWidth: 140 }}
                        {...primaryButtonProps}
                      >
                        {primaryAction}
                      </CustomButton>
                    )}
                  </Space>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
      {description && (
        <Flex justify={'space-between'} style={{ marginTop: descriptionGap }}>
          <Typography.Text style={{ display: 'flex', color: '#747679' }}>
            {description}
            {descriptionButtonProps && (
              <CustomButton {...descriptionButtonProps}>
                {descriptionButtonProps.content}
              </CustomButton>
            )}
          </Typography.Text>
          {switcherOptions && (
            <Switch
              checkedChildren={switcherOptions.onCheckedText}
              unCheckedChildren={switcherOptions.onUncheckedText}
              checked={switcherOptions.state}
              onClick={handleSwitchClick}
            />
          )}
        </Flex>
      )}
    </>
  );
};

export default ModuleTitle;
